<script>
import QrcodeVue from 'qrcode.vue'
import appConfig from "@/app.config";
// import progressBar from "../../../../components/progressBar.vue";
import { lineChartData } from "../chartData";

// import chart from "../chart.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    QrcodeVue,
  },
  data() {
    return {
      valid_form: true,
      selected_process: {},
      data: {},
      team_member: JSON.parse(localStorage.user),
      lineChartData,
      page: 1,
      limit: 20,
      tot_pages: 0,
      campaignMember: {},
      subscriptions: [],
      processes: [],
      areas: [],
      config: [],
      marketing_team_member_message: "",
      marketing_team_member_invite_url: "",
      check_box_id: null,
      radio_id: null,
      searchReferralsMode: false,
      searchReferralText: "",
      SearchAreasMode: false,
      searchAreasText: "",
      accomplish: 0,
      targets: [],
    }
  },
  computed: {

    showAreas() {
      return this.areas;
    },
  },
  methods: {
    async getReferrals(page) {
      const res = await this.http.get('marketing-campaign-team-members/get-referral/pagination', {
        page: page,
        limit: this.limit,
        marketing_campaign_id: this.$route.params?.id,
        team_member_id: this.team_member?.id
      })

      if (res.status) {
        this.subscriptions = res.data
        this.tot_pages = Math.ceil(res.count / this.limit)
      }
    },
    async searchReferrals(search) {
      const res = await this.http.get('marketing-campaign-team-members/get-referral/search', {
        search,
        page: 1,
        limit: this.limit,
        marketing_campaign_id: this.$route.params?.id,
        team_member_id: this.team_member?.id
      })

      if (res.status) {
        this.subscriptions = res.data
        this.tot_pages = Math.ceil(res.count / this.limit)
      }
    },
    async getCampaignMember() {
      const res = await this.http.post(
        "marketing-campaign-team-members/by-campaign-id", { marketing_campaign_id: this.$route.params?.id, team_member_id: this.team_member?.id }, null, true
      );
      this.campaignMember = res.data;
      this.areas = this.campaignMember?.marketing_campaign_team_member_areas;

      console.log('step 86 :', { areas: this.areas, campaignMember: this.campaignMember });

      const campaign_chart_res = await this.http.post(
        "marketing-campaign-team-members/accomplished-sum",
        { marketing_campaign_id: this.$route.params?.id, team_member_id: this.team_member?.id, type: 1 }, null, true
      );
      this.accomplish = campaign_chart_res?.data;
      const getTargets = await this.http.post(
        "marketing-campaign-team-members/get-targets",
        { marketing_campaign_id: this.$route.params?.id, team_member_id: this.team_member?.id, type: 1 }, null, true
      );
      this.targets = getTargets?.data;
    },
    getConfigCampaigns() {
      this.http
        .get('config/campaigns', null, null, true)
        .then((res) => {
          this.marketing_team_member_message = res?.data[0]?.value;
          this.marketing_team_member_invite_url = res?.data[1]?.value;
        });
    },


    cancelSearchReferralsMode() {
      this.searchReferralText = "";
      this.searchReferralsMode = false;
      this.getReferrals(1);
    },
    searchAreas() {
      console.log({ searchAreasText: this.searchAreasText });
      this.SearchAreasMode = true;
      if (this.searchAreasText === "") {
        this.areas = this.campaignMember?.marketing_campaign_team_member_areas;
      } else {
        this.areas =
          this.campaignMember?.marketing_campaign_team_member_areas?.filter((i) =>
            i?.marketing_area?.name
              ?.toLowerCase()
              ?.includes(this.searchAreasText?.toLowerCase())
          );
        console.log({ areas: this.areas });
      }
    },
    cancelSearchAreasMode() {
      this.searchAreasText = "";
      this.SearchAreasMode = false;
      this.areas = this.campaignMember?.marketing_campaign_team_member_areas;
    },
    setSelectedProcess(process) {
      this.selected_process = process;
    },
    getConfig() {
    }
  },
  async created() {
    await this.getCampaignMember();
  },
  mounted() {

    this.getConfigCampaigns();
    this.getReferrals(1)
  },


};
</script>

<template>

  <!-- heading -->
  <div class="d-flex align-items-center justify-content-between mb-4">
    <div class="d-flex align-items-center">
      <router-link :to="`/dashboard/team_member_campaigns`">
        <h4 class="m-0">
          {{ $t("menu.menuitems.campaigns.subItems.team_member_campaigns") }}
        </h4>
      </router-link>
      <div class="mx-2">\</div>
      <div>{{ campaignMember?.marketing_campaign?.name }} </div>
    </div>
  </div>
  <div class="row">
    <b-tabs content-class="p-4" class="pt-2" nav-wrapper-class="nav-item"
      nav-class="justify-content-start nav-tabs-custom">
      <!-- interviewers -->
      <b-tab :title="$t('marketing.team_member.tabs.referrals')" active>
        <div class="row mb-4">
          <div class="col-4">
            <div class="search-box chat-search-box d-flex align-items-center" style="width: 250px">
              <div class="align-items-center d-flex position-relative mx-3">
                <input @input="searchReferrals(searchReferralText)" type="text" class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')"
                  v-model="searchReferralText" style="background-color: #2a3042 !important" />
                <i class="bx bx-search-alt search-icon text-light"></i>
                <button @click="cancelSearchReferralsMode()" v-if="searchReferralsMode" class="btn-close text-light"
                  style="position: absolute; top: 12px; left: 250px"></button>
              </div>
            </div>
          </div>

          <div class="col-8 d-flex justify-content-end " style="gap: 10px;">
            <button type="button" class="btn btn-light " data-bs-toggle="modal" data-bs-target="#addInterviewerModal"
              @click="setData">
              {{ $t("marketing.team_member.campaigns.invite") }}
            </button>
            <button type="button" data-bs-toggle="modal" data-bs-target="#qrModal" class="btn btn-light">
              {{ $t('socials.generate_qr_code') }}
            </button>
            <div class="btn btn-light">
              {{ $t('socials.your_referral_code') + ' ' + '(' + this.campaignMember?.id + ')' }}
            </div>

          </div>
        </div>



        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr class="text-light text-center" style="background-color: #2a3042 !important">
              <th scope="col">{{ $t("users.userstable.id") }}</th>
              <th scope="col">
                {{ $t("marketing.team_member.subscriptions.name") }}
              </th>
              <th scope="col">
                {{ $t("marketing.team_member.subscriptions.owner") }}
              </th>
              <th scope="col">
                {{ $t("marketing.team_member.subscriptions.email") }}
              </th>
              <th scope="col">
                {{ $t("marketing.team_member.subscriptions.tel") }}
              </th>
              <th scope="col">
                {{ $t("marketing.team_member.subscriptions.status") }}
              </th>



            </tr>
          </thead>
          <tbody>
            <tr class="text-center" v-for="(subs, index) in subscriptions" :key="subs">
              <td>{{ index + 1 }}</td>
              <td>{{ subs?.pos_station_name }}</td>
              <td>{{ subs?.name }}</td>
              <td>{{ subs?.email }}</td>
              <td>{{ subs?.tel }}</td>
              <td v-if="subs?.is_active">
                <button class="btn btn-success">
                  <i class="bx bx-check"></i>
                </button>
              </td>
              <td v-else>
                <button class="btn btn-danger">
                  <i class="bx bx-time"></i>
                </button>
              </td>


            </tr>
          </tbody>
        </table>

        <!--   Apps  Pagination     -->
        <ul class="pagination pagination-rounded justify-content-center mb-2">
          <li class="page-item" :class="{ disabled: page == 1 }">
            <a class="page-link" href="javascript: void(0);" @click="getReferrals(page - 1)" aria-label="Previous">
              <i class="mdi mdi-chevron-left"></i>
            </a>
          </li>
          <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
            <a class="page-link" href="javascript: void(0);" @click="getReferrals(p)">{{
        p
      }}</a>
          </li>

          <li class="page-item" :class="{ disabled: page == tot_pages }">
            <a class="page-link" href="javascript: void(0);" @click="getReferrals(page + 1)" aria-label="Next">
              <i class="mdi mdi-chevron-right"></i>
            </a>
          </li>
        </ul>
        <!--end-->
      </b-tab>
      <!-- areas -->
      <b-tab :title="$t('marketing.team_member.tabs.areas')">
        <div class="row mb-4">
          <div class="col-4">
            <div class="search-box chat-search-box d-flex align-items-center" style="width: 250px">
              <div class="align-items-center d-flex position-relative mx-3">
                <input @keyup="searchAreas()" type="text" class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')"
                  v-model="searchAreasText" style="background-color: #2a3042 !important" />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>

          <div class="col-8"></div>
        </div>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr class="text-light text-center" style="background-color: #2a3042 !important">
              <th scope="col">{{ $t("users.userstable.id") }}</th>
              <th scope="col">
                {{ $t("marketing.team_members.table.name") }}
              </th>

              <th scope="col">{{ $t("users.userstable.created") }}</th>

            </tr>
          </thead>
          <tbody>
            <tr class="text-center" v-for="(area, index) in showAreas" :key="area">
              <td style="padding-top: 1.5rem">{{ index + 1 }}</td>
              <td style="padding-top: 1.5rem">
                {{ area?.marketing_area?.name }}
              </td>
              <td style="padding-top: 1.5rem">
                {{ area?.created?.split("T")[0] }}
              </td>

            </tr>
          </tbody>
        </table>
      </b-tab>
      <!-- progress -->
      <b-tab :title="$t('marketing.team_member.tabs.progress')">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4">
                  {{ $t("marketing.team_member.accomplish") }}
                </h4>
                <h4 class="card-sub-title mb-4">
                  {{ accomplish }}
                </h4>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4">
                  {{ $t("marketing.team_member.target") }}
                </h4>
                <h4 class="card-sub-title mb-4">
                  {{ targets[0]?.target }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>

  <!--Start Add Modal-->
  <div class="modal fade" id="addInterviewerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addInterview()">
          <div class="modal-header pt-4 px-4">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("marketing.team_member.campaigns.invite") }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body">
            <label for="message">{{ `${$t("popups.message")}` }}</label>
            <textarea v-model="marketing_team_member_message" id="message" class="form-control mb-2"
              rows="8"></textarea>
            <div class="row mt-3">
              <div class="col-md-6">
                <ShareNetwork class="btn btn-primary mb-3" network="WhatsApp" title=""
                  :url="marketing_team_member_invite_url + '/register?ref_id=' + this.campaignMember?.id"
                  :description="marketing_team_member_message">
                  <i class="bx bx-twitter text-white"></i>
                  {{ `${$t("socials.whatsapp")}` }}
                </ShareNetwork>
              </div>

              <div class="col-md-6">
                <ShareNetwork class="btn btn-primary mb-3" network="facebook" title=""
                  :url="marketing_team_member_invite_url + '/register?ref_id=' + this.campaignMember?.id"
                  :description="marketing_team_member_message">
                  <i class="bx bx-facebook text-white"></i>
                  {{ $t("socials.facebook") }}
                </ShareNetwork>
              </div>






            </div>
          </div>
          <div class="modal-footer px-4 pb-4" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("marketing.team_member.campaigns.invite") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->

  <!-- qr code Modal -->

  <div class="modal fade" id="qrModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("socials.qr_code") }}
          </h5>
        </div>
        <div class="modal-body">
          <div class="qr-image d-flex justify-content-center align-item-center">
            <qrcode-vue :value="marketing_team_member_invite_url + '/register?ref_id=' + this.campaignMember?.id"
              level="M" size="300" />
          </div>
        </div>
        <div class="buttons modal-footer d-flex">
          <!-- <button  class="btn btn-success b-color mx-1" type="button">
                  Add
                </button> -->
          <button style="margin: 0" type="button" class="btn btn-light" data-bs-dismiss="modal" aria-label="Close">
            {{ $t("popups.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--Review Details Modal-->
  <div class="modal fade" id="InterviewDetailsModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form>
          <div class="modal-header pt-4 px-4">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ selected_process?.name }}
            </h5>
            <button type="button" class="btn-close-details" data-bs-dismiss="modal" aria-label="Close"
              style="margin: 0">
              X
            </button>
          </div>

          <div class="modal-body">
            <b-tabs content-class="p-4" class="pt-2" nav-wrapper-class="nav-item"
              nav-class="justify-content-start nav-tabs-custom">
              <!-- personal tab -->
              <b-tab id="personal_tab" :title="$t('marketing.team_member.interviewers.tabs.personal')
        " active>
                <label for="name_details">{{ `${$t("popups.name")}` }}</label>
                <input v-model="selected_process.name" disabled id="name_details" class="form-control mb-2"
                  type="text" />
                <label for="tel_details">{{ `${$t("popups.tel")}` }}</label>
                <input v-model="selected_process.tel" disabled id="tel_details" class="form-control mb-2"
                  type="number" />
                <label for="tel_details">{{
        `${$t("marketing.marketing_area")}`
      }}</label>
                <select disabled id="personal_area" class="form-select" v-model="selected_process.marketing_area_id">
                  <option :value="selected_process?.marketing_area?.id">
                    {{ selected_process?.marketing_area?.name }}
                  </option>
                </select>
              </b-tab>
              <!-- process tab -->
              <b-tab id="process_tab" :title="$t('marketing.team_member.interviewers.tabs.process')">
                <div v-for="answer in selected_process?.marketing_campaign_processes_answers" :key="answer?.id"
                  class="mb-2 py-2" style="border-bottom: 1px solid #ddd">
                  <label for="name" class="mb-1">{{
        answer?.marketing_campaign_question?.question
      }}</label>
                  <!-- checkbox -->
                  <div v-if="+answer?.marketing_campaign_question
          ?.marketing_campaign_question_type_id == check_box_id
        " class="d-flex" style="flex-direction: column">
                    <label class="d-flex align-items-center my-2" style="cursor: pointer; position: relative">
                      <input style="display: none; appearance: none" type="radio" name="yesNo" class="input"
                        :checked="answer?.answer == 'true'" disabled />
                      <span style="
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: #fff;
                          " class="box check"></span>
                      {{ $t("popups.yes") }}
                    </label>
                    <label class="d-flex align-items-center my-2" style="cursor: pointer; position: relative">
                      <input style="display: none; appearance: none" type="radio" name="yesNo" class="input"
                        :checked="answer?.answer == 'false'" disabled />
                      <span style="
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: #fff;
                          " class="box cross"></span>
                      {{ $t("popups.no") }}
                    </label>
                  </div>
                  <!-- radio -->
                  <div v-if="+answer?.marketing_campaign_question
        ?.marketing_campaign_question_type_id == radio_id
        " class="d-flex" style="flex-direction: column">
                    <label v-for="option in answer?.marketing_campaign_question
        ?.marketing_campaign_question_options" :key="option?.id" class="d-flex align-items-center my-2"
                      style="cursor: pointer; position: relative">
                      <input style="display: none; appearance: none" type="radio" :name="q?.question" disabled
                        :checked="+answer?.answer == option?.id" class="input" />
                      <span style="
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: #fff;
                          " class="box radio"></span>
                      {{ option?.option }}
                    </label>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--Review Details Modal-->



</template>

<style scoped>
.box {
  transition: 0.5s;
  margin-inline: 0.75rem;
}

.box::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #4a4747;
  box-shadow: 0 -18px 0 #4a4747;
  transition: 0.5s;
}

.input:checked~.box.check::before {
  background: #0f0;
  box-shadow: 0 0 0 transparent;
}

.input:checked~.box.radio::before {
  background: #0f0;
  box-shadow: 0 0 0 transparent;
}

.input:checked~.box.cross::before {
  transform: rotate(-45deg) translate(7px, -7px);
  background: #f00;
  box-shadow: 0 0 0 transparent;
}

.box::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: #4a4747;
  box-shadow: 18px 0 0 #4a4747;
  transition: 0.5s;
}

.input:checked~.box.check::after {
  height: 50%;
  background: #0f0;
  box-shadow: 0 0 0 transparent;
}

.input:checked~.box.check {
  transform: rotate(-45deg) translate(7px, -7px);
}

.input:checked~.box.radio::after {
  height: 50%;
  background: #0f0;
  box-shadow: 0 0 0 transparent;
}

.input:checked~.box.radio {
  transform: rotate(-45deg) translate(7px, -7px);
}

.input:checked~.box.cross::after {
  transform: rotate(-45deg) translate(7px, 6px);
  background: #f00;
  box-shadow: 0 0 0 transparent;
}

.question_box {
  border-bottom: 1px solid #ddd;
}

.error {
  border: 2px solid rgb(245, 44, 44);
}

.error_border_b {
  border-bottom: 2px solid rgb(245, 44, 44) !important;
}

.share-icon {
  display: flex;
  gap: 9px;
  margin-bottom: 10px;
  font-size: 16px;
}
</style>
